import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { AuthService } from '../services/api/auth.service';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (_, state) => {
  const _router = inject(Router);

  return inject(AuthService)
    .check()
    .pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          const redirectURL =
            state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
          const urlTree = _router.parseUrl(`auth/login?${redirectURL}`);

          return of(urlTree);
        }

        return of(true);
      }),
    );
};
