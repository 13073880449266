import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "safeHtml",
    standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(v: string): SafeHtml {
    if (v) {
      return this._sanitizer.bypassSecurityTrustHtml(v);
    }
  }
}
