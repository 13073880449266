import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@core/services/api/auth.service';
import { of, switchMap } from 'rxjs';

export const authEmpresaGuard: CanActivateFn | CanActivateChildFn = () => {
  const router: Router = inject(Router);

  return inject(AuthService)
    .checkEmpresa()
    .pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          return of(router.parseUrl('auth/login'));
        }

        return of(true);
      }),
    );
};
