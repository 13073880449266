import {
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpHandlerFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {AuthService} from "@core/services/api/auth.service";

export const responseInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const _messageService = inject(MessageService);
  const _authService = inject(AuthService);

  if (
    req.url.includes('empresa-login') ||
    req.url.includes('configuracion/usuarios')
  ) {
    return next(req).pipe(
      tap((event: HttpEvent<unknown>) => {
        return true;
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if ((httpErrorResponse.status === 401)) {
          _authService.logout();

        }
        _messageService.add({
          key: httpErrorResponse.error.message === "" ? "" : "toast",
          detail:
            httpErrorResponse.error.message !== "" ? httpErrorResponse.status === 401 ? httpErrorResponse.error.message : 'Error desconocido' : 'Error desconocido',
          severity: 'warn',
          summary: 'Error',
        });

        return throwError(() => httpErrorResponse);
      })
    );
  }
    return next(req);
};
