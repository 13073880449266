import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrimeNGConfig } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxSpinnerModule, ToastModule, ConfirmDialogModule],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private _primeConfig = inject(PrimeNGConfig);

  ngOnInit(): void {
    this._primeConfig.ripple = true;
    this._primeConfig.zIndex = {
      modal: 1100, // dialog, sidebar
      overlay: 1200, // dropdown, overlaypanel
      menu: 1000, // overlay menus
      tooltip: 2000, // tooltip
    };
  }
}
