import { Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { NoAuthGuard } from '@core/guards/noAuth.guard';
import { authEmpresaGuard } from '@core/guards/auth-empresa.guard';

export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    loadChildren: () =>
      import('./pages/auth/auth.routes').then((r) => r.authRoutes),
  },
  {
    path: 'admin',
    canActivate: [authEmpresaGuard],
    canActivateChild: [authEmpresaGuard],
    loadComponent: () =>
      import('./pages/admin/layout.component').then((c) => c.LayoutComponent),
    loadChildren: () =>
      import('./pages/admin/admin.routes').then((r) => r.adminRoutes),
  },
  {
    path: 'empresas',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadComponent: () =>
      import('./pages/empresas/empresas.component').then(
        (c) => c.EmpresasComponent,
      ),
    title: 'Empresas',
  },
  {
    path: 'crear-empresa',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadComponent: () =>
      import(
        './pages/empresas/creacion-empresa/creacion-empresa.component'
      ).then((c) => c.CreacionEmpresaComponent),
    title: 'Creación Empresa',
  },
  {
    path: 'view-report',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    loadChildren: () =>
      import('./pages/view-report/view-report.routes').then(
        (r) => r.viewReportRoutes,
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin',
  },
  {
    path: 'login-in-redirect',
    pathMatch: 'full',
    redirectTo: 'empresas',
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
