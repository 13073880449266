import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { delay, finalize } from 'rxjs';
import {NgxSpinnerService} from "ngx-spinner";

export const spinnerInterceptor: HttpInterceptorFn = (req, next) => {
  const _loading = inject(NgxSpinnerService);

  _loading.show('main-spinner');

  return next(req).pipe(
    delay(100),
    finalize(async () => await _loading.hide('main-spinner')),
  );
};
