import { ApplicationConfig } from '@angular/core';
import {
  provideRouter,
  TitleStrategy,
  withComponentInputBinding,
  withHashLocation,
  withViewTransitions,
} from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { responseInterceptor } from './core/interceptors/response.interceptor';
import { spinnerInterceptor } from './core/interceptors/spinner.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TableService } from 'primeng/table';
import { PageTitleStrategyService } from './core/services/components/page-title-strategy.service';
import { requestInterceptor } from '@core/interceptors/request.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withComponentInputBinding(),
      withViewTransitions(),
      withHashLocation()
    ),
    provideHttpClient(
      withInterceptors([requestInterceptor, responseInterceptor, spinnerInterceptor])
    ),
    provideAnimations(),

    { provide: TitleStrategy, useClass: PageTitleStrategyService },

    MessageService,
    ConfirmationService,
    DialogService,
    TableService,
  ],
};
